import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useLoginMutation } from "@/lib/services/auth.services";
import { HandledError, handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { zodResolver } from "@hookform/resolvers/zod";
import i18next from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

interface Props {
  email: string;
  onForgotPassword: () => void;
  onLogin: (authToken: string) => void;
  onError: (error: HandledError) => void;
  onCancel: () => void;
}

const formSchema = z.object({
  username: z.string().email(i18next.t("validation:emailInvalid")).min(1, i18next.t("validation:emailRequired")),
  password: z.string().min(1, i18next.t("validation:passwordRequired")),
});

export default function FormLogin({ email, onForgotPassword, onCancel, onLogin, onError }: Props) {
  const { t } = useTranslation("auth");
  const login = useLoginMutation();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: email,
      password: "",
    },
    values: {
      username: email,
      password: "",
    },
  });

  async function submitHandler(values: z.infer<typeof formSchema>) {
    const { username, password } = values;

    try {
      const data = await login.mutateAsync({ username, password });
      onLogin(data.authToken);
    } catch (error) {
      onError(handleError(error));
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="space-y-6">
        <FormField
          name="username"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("email")}</FormLabel>
              <FormControl>
                <Input {...field} type="email" disabled />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="password"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center justify-between">
                {t("password")}
                <button type="button" className="text-xs text-rapide-600 font-semibold hover:underline focus:outline-none" onClick={onForgotPassword}>
                  {t("forgotPassword")}
                </button>
              </FormLabel>
              <FormControl>
                <Input type="password" autoFocus {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="mt-4 flex space-x-4">
          <Button type="button" size="md" variant="outline" onClick={onCancel} disabled={form.formState.isSubmitting}>
            {t("cancel")}
          </Button>
          <Button type="submit" size="md" className="md:w-auto w-full" loading={form.formState.isSubmitting}>
            {t("login")}
          </Button>
        </div>
      </form>
    </Form>
  );
}

