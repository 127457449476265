import { Input } from "@/components/ui/input";
import React, { useEffect, useState } from "react";

interface Props {
  onSubmit: (code: string) => void;
}

export default function CodeInput({ onSubmit }: Props) {
  const [code, setCode] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d*$/.test(value) && value.length <= 6) {
      setCode(value);
    }
  };

  const handleSubmit = () => {
    onSubmit(code);
  };

  useEffect(() => {
    if (code.length === 6) {
      handleSubmit();
    }
  }, [code]);

  return (
    <div className="flex justify-center space-x-3">
      <Input
        name="otp"
        value={code}
        onChange={handleChange}
        maxLength={6}
        size={6}
        pattern="\d*"
        autoComplete="one-time-password"
        autoFocus
        className="h-[50px] text-center border border-gray-300 rounded-md focus:outline-none focus:border-rapide-600 transition duration-150 ease-in-out"
      />
    </div>
  );
}
