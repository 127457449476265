import { GRecaptchaActionName } from "@/types/d.ts/global";
import { useEffect, useState } from "react";
import { isScriptInjected } from "../utils/scripts";


const scriptId = "recaptcha";
const scriptSrc = `https://www.google.com/recaptcha/api.js?render=${import.meta.env.VITE_RECAPTCHA_SITE_KEY}`;

export function useRecaptcha() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isScriptInjected(scriptId)) {
      setIsLoading(false);
      return;
    }

    const script = document.createElement("script");
    script.id = scriptId;
    script.src = scriptSrc;
    script.async = true;

    script.onload = () => {
      setIsLoading(false);
    };

    document.body.appendChild(script);

    return () => {};
  }, [setIsLoading]);

  async function getToken(action: GRecaptchaActionName): Promise<string> {
    return await window.grecaptcha.execute(import.meta.env.VITE_RECAPTCHA_SITE_KEY, { action: action });
  }

  return {
    isLoading,
    getToken,
  };
}