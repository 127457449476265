import { FormLabel } from "@/components/ui/form";
import cn from "@/lib/utils/cn.utils";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as React from "react";
import { MaterialSymbol } from "react-material-symbols";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "peer h-4 w-4 shrink-0 rounded-[5px] border border-silver-600 data-[state=checked]:border-simple-950 shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-simple-950 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-simple-900 data-[state=checked]:text-white dark:border-rapide-600 dark:focus-visible:ring-rapide-600 dark:data-[state=checked]:bg-rapide-600 dark:data-[state=checked]:text-simple-950",
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-current")}
    >
      <MaterialSymbol icon="check" className="-mt-[0.5px]" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

const CheckboxWithLabel = ({
  children,
  reverse,
  label,
  className,
}: React.PropsWithChildren & {
  label: string;
  reverse?: boolean;
  className?: string;
}) => {
  if (reverse) {
    return (
      <div className={cn("flex items-center space-x-2", className)}>
        <FormLabel>{label}</FormLabel>
        {children}
      </div>
    );
  }

  return (
    <div className={cn("flex items-center space-x-2", className)}>
      {children}
      <FormLabel>{label}</FormLabel>
    </div>
  );
};

export { Checkbox, CheckboxWithLabel };
