import { MaterialSymbol } from "react-material-symbols";
import { twMerge } from "tailwind-merge";

const requirements = [
  {
    test: (password: string) => password.length >= 8,
    message: "8 characters",
  },
  {
    test: (password: string) => /[A-Z]/.test(password),
    message: "1 uppercase character",
  },
  {
    test: (password: string) =>
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password),
    message: "1 special character",
  },
];

interface Props {
  password: string;
  className?: string;
}

export default function PasswordRequirements({ password, className }: Props) {
  return (
    <div className={twMerge("flex space-x-2", className)}>
      {requirements.map(({ test, message }, index) => {
        const isMet = test(password);

        return (
          <div
            key={index}
            className={`transition-color inline-flex text-xs ${
              isMet ? "text-rapide-600 font-bold opacity-100" : "text-black opacity-50 font-medium"
            }`}
          >
            {isMet ? <MaterialSymbol icon="check" size={16} /> : <MaterialSymbol icon="close" size={15} />}

            <span className="ml-0.5">{message}</span>
          </div>
        );
      })}
    </div>
  );
}
