import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Label } from "@/components/ui/label";
import { HandledError, handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { useAcceptTosMutation } from "@/lib/services/user.services";
import { zodResolver } from "@hookform/resolvers/zod";
import i18next from "i18next";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { z } from "zod";

interface Props {
  onSuccess: () => void;
  onError: (error: HandledError) => void;
  onSubmit: () => void;
}

const formSchema = z.object({
  tos: z.boolean().refine((value) => value === true, { message: i18next.t("validation:tosRequired") }),
});

export default function FormReviewTerms({ onSuccess, onSubmit, onError }: Props) {
  const { t } = useTranslation("auth");
  const acceptTosMutation = useAcceptTosMutation();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      tos: false,
    },
    values: {
      tos: false,
    },
  });

  async function submitHandler() {
    onSubmit();

    try {
      await acceptTosMutation.mutateAsync();
      onSuccess();
    } catch (error) {
      onError(handleError(error));
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="space-y-4">
        <FormField
          control={form.control}
          name="tos"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center space-x-3 space-y-0 md:space-y-0">
              <FormControl>
                <Checkbox id="acceptTermsOfUse" checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
              <Label htmlFor="acceptTermsOfUse">
                <Trans
                  i18nKey="auth:disclaimer"
                  components={{
                    tosLink: (
                      <a href={t("auth:tosLink")} className="text-rapide-600 underline hover:no-underline">
                        {t("auth:tosText")}
                      </a>
                    ),
                    privacyLink: (
                      <a href={t("auth:privacyLink")} className="text-rapide-600 underline hover:no-underline">
                        {t("auth:privacyText")}
                      </a>
                    ),
                  }}
                />
                <FormMessage />
              </Label>
            </FormItem>
          )}
        />

        <div className="pt-4 flex space-x-4">
          <Button
            type="submit"
            size="md"
            className="md:w-auto w-full"
            disabled={form.formState.isSubmitting || !form.formState.isValid}
            loading={form.formState.isSubmitting}
          >
            {t("auth:continue")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
