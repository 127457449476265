import i18next from "@/lib/i18n";
import { clicknpark } from "@/lib/services/config/api";
import queryClient from "@/lib/services/config/query-client";
import { StorageUtils, keys } from "@/lib/utils/storage.utils";
import {
  AuthPreflightRequest,
  AuthPreflightResponse,
  CPException,
  GetOtpRequest,
  GetOtpResponse,
  GetTwoFactorCodeRequest,
  GetTwoFactorCodeResponse,
  LoginResponse,
  LoginWithEmailRequest,
  LoginWithOtpRequest,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SignUpResponse,
  SignUpWithEmailRequestV3,
  SingleSignOnResponse,
  SupportedSingleSignOnProvider,
  TwoFactorMethod,
  ValidateTwoFactorCodeRequest,
  ValidateTwoFactorCodeResponse
} from "@clicknpark/sdk";
import { QueryKey, useMutation, useQuery } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

// 🛠️ Utils
// ========================================

function getInstallationId() {
  let id = StorageUtils.getItem(keys.INSTALLATION_ID);

  if (!id) {
    id = uuidv4();
    StorageUtils.setItem(keys.INSTALLATION_ID, id);
  }

  return id;
}

// 🔒 Validate auth token (GET request)
// =================================

export const VALIDATE_AUTH_TOKEN_QUERY_KEY = "validateAuthToken";

export const validateAuthToken = async (): Promise<boolean> => {
  await clicknpark.auth.validateAuthToken();
  return true; // Unless it throws, it means it's valid
};

export const useValidateAuthTokenQuery = (enabled: boolean) => {
  return useQuery<boolean, CPException, boolean, QueryKey>({
    queryKey: [VALIDATE_AUTH_TOKEN_QUERY_KEY],
    queryFn: validateAuthToken,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useLazyValidateAuthTokenQuery = () => {
  return async () => {
    return await queryClient.fetchQuery<boolean, CPException, boolean>({
      queryKey: [VALIDATE_AUTH_TOKEN_QUERY_KEY],
      queryFn: validateAuthToken,
    });
  };
};

// 🚀 Preflight (POST request)
// ========================================

export const AUTH_PREFLIGHT_MUTATION_KEY = "preflight";

export const preflight = async (
  request: AuthPreflightRequest
): Promise<AuthPreflightResponse> => {
  return await clicknpark.auth.preflightWithEmail(request.email);
};

export const useAuthPreflightMutation = () => {
  return useMutation<AuthPreflightResponse, CPException, AuthPreflightRequest>({
    mutationKey: [AUTH_PREFLIGHT_MUTATION_KEY],
    mutationFn: preflight,
  });
};

// 📝 Login (POST request)
// ========================================

const LOGIN_EMAIL_MUTATION_KEY = "login-email";

export const login = async (
  request: Pick<LoginWithEmailRequest, "username" | "password">
): Promise<LoginResponse> => {
  const language = i18next.language;
  const installationId = getInstallationId();

  return await clicknpark.auth.loginWithEmail({
    username: request.username.toLowerCase().trim(),
    password: request.password,
    locale: `${language}-CA`,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    device: { installationId, name: "web-auth" },
  });
};

export const useLoginMutation = () => {
  return useMutation<
    LoginResponse,
    CPException,
    Pick<LoginWithEmailRequest, "username" | "password">
  >({
    mutationKey: [LOGIN_EMAIL_MUTATION_KEY],
    mutationFn: login,
  });
};

// 📝 Login with OTP (POST request)
// ========================================

export const getOtp = async (
  request: GetOtpRequest
): Promise<GetOtpResponse> => {
  return await clicknpark.auth.getOtp(request);
};

const LOGIN_OTP_MUTATION_KEY = "login-otp";

export const loginWithOtp = async (
  request: LoginWithOtpRequest
): Promise<LoginResponse> => {
  return await clicknpark.auth.loginWithOtp(request);
};

export const useLoginWithOtpMutation = () => {
  return useMutation<LoginResponse, CPException, LoginWithOtpRequest>({
    mutationKey: [LOGIN_OTP_MUTATION_KEY],
    mutationFn: loginWithOtp,
  });
};

// 📝 Signup (POST request)
// ========================================

export const signUpWithEmail = async (
  request: Omit<SignUpWithEmailRequestV3, "locale" | "timezone" | "recaptchaKey">
): Promise<SignUpResponse> => {
  const language = i18next.language;
  return await clicknpark.usersV3.signupWithEmail({
    ...request,
    recaptchaKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
    locale: `${language}-CA`,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

export const SIGNUP_MUTATION_KEY = "sign-up";

export const useSignUpMutation = () => {
  return useMutation<
    SignUpResponse,
    CPException,
    Omit<SignUpWithEmailRequestV3, "locale" | "timezone" | "recaptchaKey">
  >({
    mutationKey: [SIGNUP_MUTATION_KEY],
    mutationFn: signUpWithEmail,
  });
};


// 🌐 Single Sign On (POST request)
// ========================================

export const SINGLE_SIGN_ON_MUTATION_KEY = "single-sign-on";

const singleSignOn = async (request: {
  provider: SupportedSingleSignOnProvider;
  providerToken: string;
}): Promise<SingleSignOnResponse> => {
  const language = i18next.language;
  const installationId = getInstallationId();
  return await clicknpark.auth.useSingleSignOn(request.provider, request.providerToken, {
    locale: `${language}-CA`,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    device: { installationId, name: "web-auth" },
  });
};

export const useSingleSignOnMutation = () => {
  return useMutation<
    SingleSignOnResponse,
    CPException,
    {
      provider: SupportedSingleSignOnProvider;
      providerToken: string;
    }
  >({
    mutationKey: [SINGLE_SIGN_ON_MUTATION_KEY],
    mutationFn: singleSignOn,
  });
};

// 🔑 Reset password (POST request)
// ========================================

export const RESET_PASSWORD_MUTATION_KEY = "reset-password";

export const resetPassword = async ({
  email,
  request,
}: {
  email: string;
  request: ResetPasswordRequest;
}): Promise<ResetPasswordResponse> => {
  return await clicknpark.auth.resetPassword(email, request);
};

export const useResetPasswordMutation = () => {
  return useMutation<
    ResetPasswordResponse,
    CPException,
    { email: string; request: ResetPasswordRequest }
  >({
    mutationKey: [RESET_PASSWORD_MUTATION_KEY],
    mutationFn: resetPassword,
  });
};

// 📲 2FA: Request method (GET request)
// ========================================

export const GET_TWO_FACTOR_METHODS_QUERY_KEY = "two-factor-methods";

export const getTwoFactorMethods = async (
  email: string
): Promise<TwoFactorMethod[]> => {
  return await clicknpark.auth.getTwoFactorMethods(email);
};

export const useGetTwoFactorMethodsMutation = () => {
  return useMutation<TwoFactorMethod[], CPException, string>({
    mutationKey: [GET_TWO_FACTOR_METHODS_QUERY_KEY],
    mutationFn: getTwoFactorMethods,
  });
};

// 📲 2FA: Request code (GET request)
// ========================================

/*
  Using requestTwoFactorCode can initiate a request for a two-factor code or a otp password.
  Two-factor does not authenticate the user.
*/

export const REQUEST_TWO_FACTOR_CODE_QUERY_KEY = "request-two-factor-code";

export const getTwoFactorCode = async (
  request: GetTwoFactorCodeRequest
): Promise<GetTwoFactorCodeResponse> => {
  return await clicknpark.auth.getTwoFactorCode(request);
};

// 📲 2FA: Validate code (POST request)
// ========================================

export const VALIDATE_TWO_FACTOR_CODE_MUTATION_KEY = "validate-two-factor-code";

export const validateTwoFactorCode = async (
  request: ValidateTwoFactorCodeRequest
): Promise<ValidateTwoFactorCodeResponse> => {
  return await clicknpark.auth.validateTwoFactorCode(request);
};

export const useValidateTwoFactorCodeMutation = () => {
  return useMutation<
    ValidateTwoFactorCodeResponse,
    CPException,
    ValidateTwoFactorCodeRequest
  >({
    mutationKey: [VALIDATE_TWO_FACTOR_CODE_MUTATION_KEY],
    mutationFn: validateTwoFactorCode,
  });
};
