import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import PasswordRequirements from "@/components/ui/password-requirements";
import { useRecaptcha } from "@/lib/hooks/use-recaptcha";
import { useSignUpMutation } from "@/lib/services/auth.services";
import { HandledError, handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { zodResolver } from "@hookform/resolvers/zod";
import i18next from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

interface Props {
  email: string;
  onSignup: (authToken: string) => void;
  onError: (error: HandledError) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

const formSchema = z
  .object({
    email: z.string().email(i18next.t("validation:emailInvalid")).min(1, i18next.t("validation:emailRequired")),
    password: z.string().min(1, i18next.t("validation:passwordRequired")),
    passwordConfirmation: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: i18next.t("validation:passwordsMustMatch"),
    path: ["passwordConfirmation"],
  });

export default function FormSignup({ email, onCancel, onSignup, onSubmit, onError }: Props) {
  const { t } = useTranslation("auth");
  const { getToken } = useRecaptcha();
  const signupMutation = useSignUpMutation();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email,
      password: "",
      passwordConfirmation: "",
    },
    values: {
      email,
      password: "",
      passwordConfirmation: "",
    },
  });

  async function submitHandler(values: z.infer<typeof formSchema>) {
    onSubmit();

    const { email, password } = values;

    try {
      const recaptchaToken = await getToken("signup");
      const data = await signupMutation.mutateAsync({ email, password, recaptchaToken });
      onSignup(data.authToken);
    } catch (error) {
      onError(handleError(error));
    }
  }

  const password = form.watch("password");

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="space-y-4">
        <FormField
          name="email"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("signupEmail")}</FormLabel>
              <FormControl>
                <Input {...field} type="email" disabled />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="password"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center justify-between">{t("password")}</FormLabel>
              <FormControl>
                <div>
                  <Input type="password" autoFocus {...field} />
                  <PasswordRequirements password={password} className="pb-2 mt-3" />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="passwordConfirmation"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center justify-between">{t("passwordConfirm")}</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="mt-4 flex space-x-4">
          <Button type="button" size="md" variant="outline" onClick={onCancel} disabled={form.formState.isSubmitting}>
            {t("auth:cancel")}
          </Button>

          <Button type="submit" size="md" className="md:w-auto w-full" disabled={form.formState.isSubmitting} loading={form.formState.isSubmitting}>
            {t("auth:signup")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
