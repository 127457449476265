import Wrapper from "@/components/layout/wrapper";
import Logout from "@/components/logout";
import { ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";

type ButtonProps = {
  title: string;
  description: string;
  href: string;
};

const PlatformButton = ({ title, description, href }: ButtonProps) => (
  <a
    className="w-full text-left p-5 border-2 border-gray-200 hover:border-rapide-600 rounded-lg gap-4 flex justify-between items-center group"
    href={href}
  >
    <div className="flex-1 space-y-1">
      <strong className="text-md">{title}</strong>
      <p className="text-sm text-slate-600">{description}</p>
    </div>
    <ArrowRight
      size={24}
      className="text-rapide-600 transform transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:translate-x-2"
    />
  </a>
);

export default function SelectPlatform() {
  const { t } = useTranslation(["auth"]);

  return (
    <Wrapper title={t("auth:selectPlatformTitle")} description={t("auth:selectPlatformText")}>
      <div className="space-y-4 mt-2">
        <PlatformButton
          {...{
            title: t("auth:logInWebApp"),
            description: t("auth:logInWebAppDescription"),
            href: import.meta.env.VITE_CLICKNPARK_WEB_APP_URL as string,
          }}
        />

        <PlatformButton
          {...{
            title: t("auth:logInDashboard"),
            description: t("auth:logInDashboardDescription"),
            href: import.meta.env.VITE_CLICKNPARK_DASHBOARD_URL as string,
          }}
        />
      </div>

      <Logout />
    </Wrapper>
  );
}
