import i18next from "i18next";
import parsePhoneNumberFromString, { CountryCode } from "libphonenumber-js";

export class PhoneValidationUtils {
  public static extractAndValidateNationalNumber(phoneNumber: string, countryCode: CountryCode): string {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, countryCode);

    if (!parsedNumber || !parsedNumber.isValid()) {
      throw new Error(i18next.t("validation:phoneInvalid"));
    }

    return parsedNumber.nationalNumber.toString();
  }
}
