import FormSignup from "@/components/containers/forms/form-signup";
import Wrapper from "@/components/layout/wrapper";
import IError from "@/components/ui/error";
import useOnAuth from "@/lib/hooks/use-on-auth";
import { FormattedError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { StorageUtils, keys } from "@/lib/utils/storage.utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Signup() {
  const { t } = useTranslation(["auth"]);
  const navigate = useNavigate();
  const { authenticate } = useOnAuth();
  const email = StorageUtils.getSessionItem(keys.AUTH_EMAIL) || "";
  const [error, setError] = useState<FormattedError | undefined>();

  function onSignup(authToken: string) {
    authenticate(authToken);
    navigate("/signup/profile", { replace: true });
    StorageUtils.removeSessionItem(keys.AUTH_EMAIL);
    StorageUtils.removeSessionItem(keys.AUTH_NEEDS_COMPLETION);
  }

  useEffect(() => {
    if (!email) {
      navigate("/");
    }
  }, [email])

  function onSubmit() {
    setError(undefined);
  }

  function onError(error: FormattedError) {
    setError(error);
  }

  function onCancel() {
    navigate("/");
  }
  return (
    <Wrapper title={t("auth:signupTitle")} description={t("auth:signupText")}>
      {error ? <IError title={error.message} align="center" /> : null}

      <FormSignup
        {...{
          email: email,
          onSignup,
          onCancel,
          onSubmit,
          onError,
        }}
      />
    </Wrapper>
  );
}
