import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  description?: string;
  children: React.ReactNode;
  widthClass?: string;
  className?: string;
}

export default function Wrapper({ title, description, children, widthClass = "w-full", className }: Props) {
  const { t } = useTranslation(["common"]);
  const { language, alternateLanguage, switchLanguage } = useLanguageUtils();

  return (
    <div className="lg:bg-silver-900 bg-white lg:h-screen lg:pt-0 pt-16 lg:flex ">
      <div
        className={clsx(
          "relative lg:w-1/2 lg:max-w-xl lg:p-20 sm:p-10 p-8 w-full flex flex-col justify-center items-center bg-white",
          className,
          widthClass
        )}
      >
        <div className="w-full">
          <img src={`/images/layout/logo-${language}-CA.svg`} alt="Logo" className="w-48 mb-10" />

          <Card className="shadow-none border-none p-0">
            {title || description ? (
              <CardHeader className="pb-3">
                {title ? <h1 className="text-2xl font-bold font-heading">{title}</h1> : null}
                {description ? <p className="mt-2 text-base">{description}</p> : null}
              </CardHeader>
            ) : null}

            <CardContent>{children}</CardContent>
          </Card>
        </div>

        <footer className="mt-24 flex justify-center text-xs text-slate-500">
          <a href={t("privacyPolicyUrl")} target="_blank">
            {t("privacyPolicy")}
          </a>
          <span className="mx-2">&middot;</span>
          <a href={t("termsOfUseUrl")} target="_blank">
            {t("termsOfUse")}
          </a>
        </footer>
      </div>

      <Button
        size="sm"
        type="button"
        onClick={() => switchLanguage(alternateLanguage)}
        className="absolute top-5 right-5"
        color="secondary"
        variant="outline"
      >
        {t(`common:${alternateLanguage}`)}
      </Button>

      <div
        className="flex-1 bg-cover bg-right-bottom"
        style={{
          backgroundImage: `url(/images/layout/brand-line.svg)`,
        }}
      />
    </div>
  );
}
