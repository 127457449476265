import Loader from "@/components/layout/loader";
import useOnAuth from "@/lib/hooks/use-on-auth";
import useOnLogout from "@/lib/hooks/use-on-logout";
import useOnRedirect from "@/lib/hooks/use-on-redirect";
import { removeAuthTokenHeader, setAuthTokenHeader } from "@/lib/services/config/api";
import { configureSentry } from "@/lib/services/sentry.services";
import { useGetUserQuery } from "@/lib/services/user.services";
import { StorageUtils, keys } from "@/lib/utils/storage.utils";
import Login from "@/routes/login";
import LoginOtp from "@/routes/login-otp";
import Preflight from "@/routes/preflight";
import Redirecting from "@/routes/redirecting";
import ResetPassword from "@/routes/reset-password";
import ReviewTerms from "@/routes/review-terms";
import SelectPlatform from "@/routes/select-platform";
import Signup from "@/routes/signup";
import SignupProfile from "@/routes/signup-profile";
import { CPException } from "@clicknpark/sdk";
import { CPUserV3 } from "@clicknpark/sdk/dist/types/entities/v3/users/CPUserV3";
import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Outlet, createBrowserRouter, useLocation, useNavigate, useSearchParams } from "react-router-dom";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

configureSentry();

const storedAuthToken = Cookies.get(keys.AUTH_TOKEN);
const bifrostEmail = Cookies.get(keys.BIFROST_EMAIL);

// Runs once on app load to set the auth token header
if (storedAuthToken) {
  setAuthTokenHeader(storedAuthToken);
} else {
  removeAuthTokenHeader();
}

const isProfileComplete = (user: CPUserV3): boolean => {
  return (
    typeof user.firstName !== "undefined" &&
    typeof user.lastName !== "undefined" &&
    typeof user.phone !== "undefined" &&
    typeof user.email !== "undefined"
  );
};

function App() {
  const { t } = useTranslation("common");
  const location = useLocation();
  const navigate = useNavigate();
  const redirect = useOnRedirect();
  const { isOwnerAccount } = useOnAuth();
  const logout = useOnLogout();
  const [searchParams] = useSearchParams();
  const [cookie, __, removeCookie] = useCookies([keys.AUTH_TOKEN]);
  const authToken = cookie[keys.AUTH_TOKEN] as string | undefined;
  const getUserQuery = useGetUserQuery({ enabled: typeof authToken === "undefined" ? false : true });
  const userLoggedIn = !getUserQuery.isLoading && getUserQuery.data;
  const needsToReviewTerms = userLoggedIn && getUserQuery.data?.tosAgreementStatus !== "up-to-date";
  const needsProfileCompletion = userLoggedIn && !isProfileComplete(getUserQuery.data);

  async function redirectUser() {
    if (await isOwnerAccount()) {
      navigate("/select-platform");
    } else {
      redirect();
    }
  }

  useEffect(() => {
    const referrer = searchParams.get("referrer");
    referrer && StorageUtils.setSessionItem(keys.AUTH_REFERRER, referrer);
  }, [searchParams]);

  useEffect(() => {
    if (!authToken || (authToken && !getUserQuery.isLoading)) return;

    if (getUserQuery.isError) {
      if (getUserQuery.error instanceof CPException) {
        if (getUserQuery.error.code === 4000 || getUserQuery.error.code === 4001) {
          logout();
          return;
        }
      }
    }
  }, [removeCookie, getUserQuery, authToken]);

  /*
  Redirection logic explained:

  this app only serves the following cases:

  - user logs in and is redirected to the location received in the ?redirect param, or web-app by default
  - user logs in and is redirected to the /select-platform page if they are an owner, then they decide manually where to go
  - user logs in and is redirected to the /signup/profile page if they are a new user or if their profile is imcomplete
  - user logs in and is redirected to the /review-terms page if they need to review the terms of service
*/

  // has auth token but user is not loaded yet
  if (authToken && getUserQuery.isLoading) {
    return <Loader />;
  }

  if (needsProfileCompletion && location.pathname !== "/signup/profile") {
    navigate("/signup/profile");
    return <Redirecting />;
  }

  if (needsToReviewTerms && location.pathname !== "/review-terms" && location.pathname !== "/signup/profile") {
    navigate("/review-terms");
    return <Redirecting />;
  }

  // user logged in, not on a page that requires action, we should redirect
  if (userLoggedIn && location.pathname !== "/signup/profile" && location.pathname !== "/select-platform" && location.pathname !== "/review-terms") {
    redirectUser();
    return <Redirecting />;
  }

  // otherwise, show the page and keep the redirect in session storage
  if (!getUserQuery.isLoading && searchParams.get("redirect")) {
    const redirect = decodeURIComponent(searchParams.get("redirect") as string);
    StorageUtils.setSessionItem(keys.AUTH_REDIRECT, redirect as string);
  }

  if (bifrostEmail && authToken) {
    return (
      <div>
        <div className="bg-rapide-600 py-3 text-center text-sm font-semibold text-white">{t("loggedInAs", { email: bifrostEmail })}</div>
        <Outlet />
      </div>
    );
  }

  return <Outlet />;
}

export default sentryCreateBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Preflight />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/login/otp",
        element: <LoginOtp />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/signup/profile",
        element: <SignupProfile />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/select-platform",
        element: <SelectPlatform />,
      },
      {
        path: "review-terms",
        element: <ReviewTerms />,
      },
    ],
  },
]);
