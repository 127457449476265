const envExtraHosts = import.meta.env.VITE_EXTRA_ACCEPTED_REDIRECT_HOSTS as string;
let extra = "";
if (envExtraHosts) extra = envExtraHosts;
const extraArray = extra.split(",");

export const acceptedRedirectHosts = [
  "checkout.clicknpark.com",
  "dev-checkout.clicknpark.com",
  "app.clicknpark.com",
  "dev-app.clicknpark.com",
  "dashboard.clicknpark.com",
  "development.dashboard.clicknpark.com",
  "staging.dashboard.clicknpark.com",
  "clicknpark.com",
  "development.clicknpark.com",
  "staging.clicknpark.com",
  ...extraArray,
];

export const isSafeToRedirect = (url: string) => {
  const urlObject = new URL(url);
  const host = urlObject.host;
  return acceptedRedirectHosts.includes(host);
};
