import { isSafeToRedirect } from "@/lib/configs/redirect.config";
import { StorageUtils, keys } from "@/lib/utils/storage.utils";
import { useSearchParams } from "react-router-dom";

export default function useOnRedirect() {
  const [searchParams] = useSearchParams();
  let redirect: string | null = null;

  if (searchParams.get("redirect")) {
    redirect = decodeURIComponent(searchParams.get("redirect") as string);
  } else if (StorageUtils.getSessionItem(keys.AUTH_REDIRECT)) {
    redirect = StorageUtils.getSessionItem(keys.AUTH_REDIRECT);
  }

  return () => {
    if (!redirect || !isSafeToRedirect(redirect)) {
      redirect = import.meta.env.VITE_CLICKNPARK_DEFAULT_REDIRECT as string;
    }

    StorageUtils.clear();
    window.location.replace(redirect);
  };
}
