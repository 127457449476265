import FormPreflight from "@/components/containers/forms/form-preflight";
import Wrapper from "@/components/layout/wrapper";
import IError from "@/components/ui/error";
import useOnAuth from "@/lib/hooks/use-on-auth";
import { FormattedError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { StorageUtils, keys } from "@/lib/utils/storage.utils";
import Redirecting from "@/routes/redirecting";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Preflight() {
  const { t } = useTranslation(["auth"]);
  const navigate = useNavigate();
  const { authenticate, redirect, isOwnerAccount, needsToReviewTerms } = useOnAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<FormattedError | undefined>();

  function onPreflight(email: string, hasAccount: boolean, hasCompletedSignup: boolean) {
    if (hasAccount) {
      StorageUtils.setSessionItem(keys.AUTH_EMAIL, email);

      if (!hasCompletedSignup) {
        navigate("/login/otp");
      } else {
        navigate("/login");
      }
    } else {
      StorageUtils.setSessionItem(keys.AUTH_EMAIL, email);
      navigate("/signup");
    }
  }

  function onSubmit() {
    setError(undefined);
  }

  function onError(error: FormattedError) {
    setError(error);
  }

  async function onSingleSignOn(authToken: string) {
    setLoading(true);
    authenticate(authToken);
    if (await needsToReviewTerms()) {
      navigate("/review-terms");
    } else if (await isOwnerAccount()) {
      navigate("/select-platform");
    } else {
      redirect();
    }
  }

  return (
    <Wrapper title={t("auth:loginTitle")} description={t("auth:loginText")}>
      {error ? <IError title={error.message} align="center" /> : null}

      {loading ? (
        <Redirecting />
      ) : (
        <FormPreflight
          {...{
            onPreflight,
            onSingleSignOn,
            onSubmit,
            onError,
          }}
        />
      )}
    </Wrapper>
  );
}
