import cn from "@/lib/utils/cn.utils";

type HeadingLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
type HeadingSize = "sm" | "md" | "lg";
type HeadingColor = "rapide" | "black" | "zinc";

interface HeadingProps extends React.ComponentPropsWithoutRef<"h1" | "h2" | "h3" | "h4" | "h5" | "h6"> {
  children: React.ReactNode;
  level?: HeadingLevel;
  size?: HeadingSize;
  color?: HeadingColor;
  className?: string;
}

const sizeStyles: Record<string, Record<string, string>> = {
  sm: {
    h1: ` sm:text-2xl text-xl`,
    h2: ` sm:text-xl text-md`,
    h3: ` sm:text-lg text-sm`,
    h4: ` sm:text-base text-sm`,
    h5: ` sm:text-sm text-xs`,
    h6: ` sm:text-xs text-xs`,
  },
  md: {
    h1: ` sm:text-3xl text-xl`,
    h2: ` sm:text-2xl text-lg`,
    h3: ` sm:text-xl text-md`,
    h4: ` sm:text-lg text-md`,
    h5: ` sm:text-md text-sm`,
    h6: ` sm:text-sm text-xs`,
  },
  lg: {
    h1: ` sm:text-4xl text-2xl`,
    h2: ` sm:text-3xl text-xl`,
    h3: ` sm:text-2xl text-lg`,
    h4: ` sm:text-xl text-lg`,
    h5: ` sm:text-lg text-md`,
    h6: ` sm:text-md text-sm`,
  },
};

const colorStyles: Record<string, string> = {
  rapide: ` text-rapide-600`,
  black: ` text-black`,
  zinc: ` text-zinc-800`,
};

export default function Heading({ className, level = "h1", size = "md", color = "black", ...props }: HeadingProps) {
  const styles: Record<HeadingLevel, string> = {
    h1: `font-bold`,
    h2: `font-bold`,
    h3: `font-bold`,
    h4: `font-bold`,
    h5: `font-bold`,
    h6: `font-bold`,
  };

  styles[level] += sizeStyles[size][level];
  styles[level] += colorStyles[color];

  switch (color) {
    case "rapide":
      styles[level] += ` text-rapide-600`;
      break;
    case "black":
      styles[level] += ` text-black`;
      break;
    case "zinc":
      styles[level] += ` text-zinc-800`;
      break;
  }

  const componentProps = {
    ...props,
    className: cn(styles[level], className),
  };

  let Component = <h1 {...componentProps} />;

  switch (level) {
    case "h2":
      Component = <h2 {...componentProps} />;
      break;
    case "h3":
      Component = <h3 {...componentProps} />;
      break;
    case "h4":
      Component = <h4 {...componentProps} />;
      break;
    case "h5":
      Component = <h5 {...componentProps} />;
      break;
    case "h6":
      Component = <h6 {...componentProps} />;
      break;
  }

  return Component;
}
