import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import SingleSignOn from "@/components/containers/forms/sso";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useAuthPreflightMutation } from "@/lib/services/auth.services";
import { HandledError, handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { useTranslation } from "react-i18next";

interface Props {
  onPreflight: (email: string, hasAccount: boolean, hasCompletedSignup: boolean) => void;
  onSingleSignOn: (authToken: string) => void;
  onSubmit: () => void;
  onError: (error: HandledError) => void;
}

const formSchema = z.object({
  email: z.string().email(),
});

export default function FormPreflight({ onPreflight, onSingleSignOn, onSubmit, onError }: Props) {
  const { t } = useTranslation("auth");

  const preflight = useAuthPreflightMutation();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  async function submitHandler(values: z.infer<typeof formSchema>) {
    onSubmit();
    const { email } = values;

    try {
      const data = await preflight.mutateAsync({ email });
      onPreflight(email, data.hasAccount, data.hasCompletedSignUp);
    } catch (error) {
      onError(handleError(error));
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="space-y-4">
        <FormField
          name="email"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("email")}</FormLabel>
              <FormControl>
                <Input {...field} autoFocus type="email" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" className="w-full" loading={form.formState.isSubmitting}>
          {t("continueWithEmail")}
        </Button>

        <SingleSignOn onSuccess={onSingleSignOn} />
      </form>
    </Form>
  );
}
