import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import useOnLogout from "@/lib/hooks/use-on-logout";
import { useGetUserQuery } from "@/lib/services/user.services";
import { useTranslation } from "react-i18next";

export default function Logout() {
  const { t } = useTranslation(["auth"]);
  const logout = useOnLogout();

  const getUserQuery = useGetUserQuery();
  const user = getUserQuery.data;

  return (
    <div className="mt-5 text-center">
      <Separator className="my-5" />
      <div className="flex gap-4 items-center justify-between">
        {user ? <p className="text-left text-sm text-slate-500">{t("auth:connectedAs", { user: user.email })}</p> : null}
        <Button variant="link" onClick={logout}>
          {t("auth:logout")}
        </Button>
      </div>
    </div>
  );
}
