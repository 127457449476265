import { Button } from "@/components/ui/button";
import { AiFillApple } from "react-icons/ai";
import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
}

export default function SocialLoginButtonApple({ className }: Props) {
  return (
    <Button variant="outline" color="secondary" className={twMerge("flex items-center space-x-2", className)}>
      <AiFillApple className="text-black w-5 h-5" />
      <span className="md:block hidden">Apple</span>
    </Button>
  );
}
