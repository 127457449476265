import auth from "./auth.json";
import common from "./common.json";
import errors from "./errors.json";
import validation from "./validation.json";

const locale = {
  auth,
  common,
  errors,
  validation,
};

const enLocale = locale; // Simplifies copy paste... every locale index is the same

export default enLocale;
