import { Button } from "@/components/ui/button";
import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
}

export default function SocialLoginButtonGoogle({ className }: Props) {
  return (
    <Button variant="outline" color="secondary" className={twMerge("flex items-center space-x-2", className)}>
      <img src="/images/google.svg" alt="Google" className="w-4 h-4" />
      <span className="md:block hidden">Google</span>
    </Button>
  );
}
