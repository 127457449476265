import { Button } from "@/components/ui/button";
import CodeInput from "@/components/ui/custom-form-ui/2fa/CodeInput";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import Heading from "@/components/ui/heading";
import { Input } from "@/components/ui/input";
import PasswordRequirements from "@/components/ui/password-requirements";
import { Separator } from "@/components/ui/separator";
import Spinner from "@/components/ui/spinner";
import {
  getTwoFactorCode,
  useGetTwoFactorMethodsMutation,
  useLoginMutation,
  useResetPasswordMutation,
  useValidateTwoFactorCodeMutation,
} from "@/lib/services/auth.services";
import { AppError, HandledError, handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { EncryptedData, TwoFactorMethod } from "@clicknpark/sdk";
import { zodResolver } from "@hookform/resolvers/zod";
import i18next from "i18next";
import { Mail, Phone } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

interface Props {
  email: string;
  onCancel: () => void;
  onSubmit: () => void;
  onSuccess: (authToken: string) => void;
  onError: (error: HandledError) => void;
}

const formSchema = z
  .object({
    newPassword: z.string().min(1, i18next.t("validation:passwordRequired")),
    newPasswordConfirmation: z.string(),
  })
  .refine((data) => data.newPassword === data.newPasswordConfirmation, {
    message: i18next.t("validation:passwordsMustMatch"),
    path: ["newPasswordConfirmation"], // specify the field that the error is associated with
  });

export default function FormResetPassword({ email, onCancel, onSubmit, onSuccess, onError }: Props) {
  const { t } = useTranslation("auth");

  const [loading, setLoading] = useState(false);
  const [methods, setMethods] = useState<TwoFactorMethod[]>([]);
  const [form, setForm] = useState<"methods" | "code" | "reset-password">("methods");
  const [encryptedData, setEncryptedData] = useState<EncryptedData | undefined>();
  const [savedCode, setSavedCode] = useState<string | undefined>();
  const [selectedMethod, setSelectedMethod] = useState<TwoFactorMethod | undefined>();

  const twoFactorMethods = useGetTwoFactorMethodsMutation();
  const validatetwoFactorCode = useValidateTwoFactorCodeMutation();
  const resetPassword = useResetPasswordMutation();
  const login = useLoginMutation();

  const resetPasswordForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      newPassword: "",
      newPasswordConfirmation: "",
    },
  });

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const data = await twoFactorMethods.mutateAsync(email);
        setMethods(data);
      } catch (error) {
        onError(handleError(error));
      }

      setLoading(false);
    })();
  }, [email]);

  async function onMethodSelect(method: TwoFactorMethod) {
    setLoading(true);

    try {
      setSelectedMethod(method);
      const data = await getTwoFactorCode({ methodType: method.type, email });
      setEncryptedData(data.encryptedData);
      setForm("code");
    } catch (error) {
      onError(handleError(error));
    }

    setLoading(false);
  }

  async function onCodeSubmit(code: string) {
    onSubmit();

    if (!selectedMethod) throw new Error("No selected method");
    if (!encryptedData) throw new Error("No encrypted data");

    setLoading(true);

    try {
      const { valid } = await validatetwoFactorCode.mutateAsync({
        email,
        code,
        encryptedData,
      });

      if (valid) {
        setForm("reset-password");
        setSavedCode(code);
      } else {
        throw new AppError("invalidOtc", "The one-time code you entered is invalid. Please try again.");
      }
    } catch (error) {
      onError(handleError(error));
    }

    setLoading(false);
  }

  async function onPasswordReset(values: z.infer<typeof formSchema>) {
    onSubmit();
    const { newPassword } = values;

    if (!savedCode) throw new Error("No saved code");
    if (!encryptedData) throw new Error("No encrypted data");

    try {
      const { success } = await resetPassword.mutateAsync({
        email,
        request: {
          newPassword,
          code: savedCode,
          encryptedData,
        },
      });

      if (success) {
        try {
          const data = await login.mutateAsync({
            username: email,
            password: newPassword,
          });

          onSuccess(data.authToken);
        } catch (error) {
          onError(handleError(error));
        }
      }
    } catch (error) {
      onError(handleError(error));
    }
  }

  const newPassword = resetPasswordForm.watch("newPassword");

  if (loading) {
    return (
      <div className="min-h-[100px] flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {form === "methods" ? (
        <>
          <ul className="space-y-4">
            {methods.map((method) => (
              <li
                key={method.type}
                onClick={() => onMethodSelect(method)}
                className="flex items-center flex-1 p-3 pt-2 border rounded-md cursor-pointer hover:bg-silver-900/50 hover:ring-2 ring-rapide-600 transition-all ease-in-out duration-150"
              >
                <div className="ml-1.5 flex items-center">
                  {method.type === "sms" ? <Phone className="text-rapide-600 w-5 h-5" /> : <Mail className="text-rapide-600 w-5 h-5" />}
                </div>

                <div className="pl-4 ml-4 border-l border-silver-800">
                  <strong className="text-sm">{t(`method-${method.type}`)}: </strong>
                  <span className="text-slate-700 text-sm">{method.destination}</span>
                </div>
              </li>
            ))}
          </ul>

          <Button type="button" size="md" variant="outline" onClick={onCancel} disabled={loading} className="mt-5">
            {t("cancel")}
          </Button>
        </>
      ) : null}

      {form === "code" ? (
        <>
          <Heading level="h3">
            {t("twoFactorCodeSent", {
              method: selectedMethod?.type,
              destination: selectedMethod?.destination,
            })}
          </Heading>
          <p>{t("twoFactorCodeSentText")}</p>
          <Separator className="my-5" />
          <CodeInput onSubmit={onCodeSubmit} />
          <Button type="button" onClick={() => setForm("methods")} className="mt-5" variant="outline" size="sm">
            {t("selectAnotherMethod")}
          </Button>
        </>
      ) : null}

      {form === "reset-password" ? (
        <>
          <Form {...resetPasswordForm}>
            <form onSubmit={resetPasswordForm.handleSubmit(onPasswordReset)} className="space-y-4">
              <FormField
                name="newPassword"
                control={resetPasswordForm.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="flex items-center justify-between">{t("newPassword")}</FormLabel>
                    <FormControl>
                      <div>
                        <Input type="password" autoFocus {...field} />
                        <PasswordRequirements password={newPassword} className="pb-2 mt-3" />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="newPasswordConfirmation"
                control={resetPasswordForm.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="flex items-center justify-between">{t("newPasswordConfirm")}</FormLabel>
                    <FormControl>
                      <Input type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="mt-4 flex space-x-4">
                <Button type="button" variant="outline" onClick={onCancel} disabled={resetPasswordForm.formState.isSubmitting}>
                  {t("cancel")}
                </Button>

                <Button type="submit" className="md:w-auto w-full" loading={resetPasswordForm.formState.isSubmitting}>
                  {t("resetPasswordAndLogin")}
                </Button>
              </div>
            </form>
          </Form>
        </>
      ) : null}
    </>
  );
}
