/* Configure the application and render the root component */

// Polyfills

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isToday from "dayjs/plugin/isToday";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.extend(isToday);

// Translations

import "./lib/i18n";

// CSS & libraries

import "@/assets/scss/main.scss";
import "react-material-symbols/rounded";

// Components

import { Toaster } from "@/components/ui/toaster";
import router from "@/router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PostHogProvider } from "posthog-js/react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { RouterProvider } from "react-router-dom";

/* Create the react-query client */

const queryClient = new QueryClient();

// Removes auto-zoom on iPhone

if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
  const viewport = document.querySelector('meta[name="viewport"]');
  viewport?.setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1");
}

TagManager.initialize({ gtmId: import.meta.env.VITE_GTM_ID });

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <CookiesProvider defaultSetOptions={{ path: "/" }}>
    <PostHogProvider apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY} options={{ api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST }}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Toaster />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </PostHogProvider>
  </CookiesProvider>
);
