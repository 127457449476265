import FormReviewTerms from "@/components/containers/forms/form-review-terms";
import Wrapper from "@/components/layout/wrapper";
import Logout from "@/components/logout";
import IError from "@/components/ui/error";
import useOnAuth from "@/lib/hooks/use-on-auth";
import { FormattedError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import Redirecting from "@/routes/redirecting";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function ReviewTerms() {
  const { t } = useTranslation(["auth"]);
  const navigate = useNavigate();
  const { isOwnerAccount, redirect } = useOnAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<FormattedError | undefined>();

  function onSubmit() {
    setError(undefined);
  }

  function onError(error: FormattedError) {
    setError(error);
  }

  async function onSuccess() {
    setLoading(true);
    if (await isOwnerAccount()) {
      navigate("/select-platform");
    } else {
      redirect();
    }
  }

  return (
    <Wrapper title={t("auth:reviewTermsTitle")} description={t("auth:reviewTermsText")}>
      {error ? <IError title={error.message} align="center" /> : null}

      {loading ? (
        <Redirecting />
      ) : (
        <FormReviewTerms
          {...{
            onSuccess,
            onSubmit,
            onError,
          }}
        />
      )}

      <Logout />
    </Wrapper>
  );
}
