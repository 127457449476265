import FormResetPassword from "@/components/containers/forms/form-reset-password";
import Wrapper from "@/components/layout/wrapper";
import IError from "@/components/ui/error";
import useOnAuth from "@/lib/hooks/use-on-auth";
import { FormattedError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { StorageUtils, keys } from "@/lib/utils/storage.utils";
import Redirecting from "@/routes/redirecting";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {
  const { t } = useTranslation(["auth"]);
  const navigate = useNavigate();
  const { authenticate, isOwnerAccount, needsToReviewTerms, redirect } = useOnAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<FormattedError | undefined>();

  function onCancel() {
    navigate("/");
  }

  function onSubmit() {
    setError(undefined);
  }

  function onError(error: FormattedError) {
    setError(error);
  }

  async function onSuccess(authToken: string) {
    setLoading(true);
    authenticate(authToken);
    if (await needsToReviewTerms()) {
      navigate("/review-terms");
    } else if (await isOwnerAccount()) {
      navigate("/select-platform");
    } else {
      redirect();
    }
  }

  return (
    <Wrapper title={t("auth:resetPasswordTitle")}>
      {error ? <IError title={error.title} message={error.message} /> : null}

      {loading ? (
        <Redirecting />
      ) : (
        <FormResetPassword
          {...{
            email: StorageUtils.getSessionItem(keys.AUTH_EMAIL) || "",
            onSuccess,
            onCancel,
            onSubmit,
            onError,
          }}
        />
      )}
    </Wrapper>
  );
}
