import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { HandledError, handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import {
  useAcceptTosMutation,
  useGetUserQuery,
  userUpdateUserSettingsMutation,
  useSetBusinessTypeMutation,
  useUpdateUserMutation,
} from "@/lib/services/user.services";
import { PhoneFormattingUtils } from "@/lib/utils/formatting.utils";
import { keys, StorageUtils } from "@/lib/utils/storage.utils";
import { PhoneValidationUtils } from "@/lib/utils/validation.utils";
import { CPException } from "@clicknpark/sdk";
import { UpdateUserRequest } from "@clicknpark/sdk/dist/types/services/latest/user/UserServiceTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import i18next from "i18next";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

interface Props {
  onSuccess: () => void;
  onError: (error: HandledError) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

const formSchema = z.object({
  firstName: z.string().min(1, i18next.t("validation:firstNameRequired")),
  lastName: z.string().min(1, i18next.t("validation:lastNameRequired")),
  phone: z.string().refine((value) => {
    try {
      // prevent unhandled error when phone is empty because it will throw an error
      PhoneValidationUtils.extractAndValidateNationalNumber(value, "CA");
      return true;
    } catch (error) {
      return false;
    }
  }),
  tos: z.boolean().refine((value) => value === true, { message: i18next.t("validation:tosRequired") }),
  newsletterOptIn: z.boolean().optional(),
  businessType: z.string().optional(),
});

export default function FormSignupProfile({ onSuccess, onCancel, onSubmit, onError }: Props) {
  const { t } = useTranslation("auth");
  const navigate = useNavigate();

  const getUserQuery = useGetUserQuery();
  const updateUserMutation = useUpdateUserMutation();
  const setBusinessTypeMutation = useSetBusinessTypeMutation();
  const updateUserSettingsMutation = userUpdateUserSettingsMutation();
  const acceptTosMutation = useAcceptTosMutation();

  const displayBusinessType = StorageUtils.getSessionItem(keys.AUTH_REFERRER) === "dashboard" ? true : false;

  useEffect(() => {
    if (getUserQuery.error && getUserQuery.error instanceof CPException) {
      navigate("/");
    }
  }, [getUserQuery.error]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      tos: false,
      newsletterOptIn: false,
      businessType: "individual",
    },
    values: {
      firstName: getUserQuery.data?.firstName || "",
      lastName: getUserQuery.data?.lastName || "",
      phone: getUserQuery.data?.phone?.number || "",
      newsletterOptIn: false,
      tos: false,
      businessType: "individual",
    },
  });

  async function submitHandler(values: z.infer<typeof formSchema>) {
    onSubmit();

    const { firstName, lastName, phone, newsletterOptIn } = values;

    try {
      let request: UpdateUserRequest = {
        updates: {
          firstName,
          lastName,
          phone: { number: PhoneFormattingUtils.getE164Value(phone) },
        },
      };

      await updateUserMutation.mutateAsync(request);

      if (displayBusinessType) {
        await setBusinessTypeMutation.mutateAsync(values.businessType as "individual" | "company");
      }

      await updateUserSettingsMutation.mutateAsync({
        email: {
          acceptsMarketing: newsletterOptIn || false,
        },
        push: {
          acceptsMarketing: newsletterOptIn || false,
        },
      });

      await acceptTosMutation.mutateAsync();

      onSuccess();
    } catch (error) {
      onError(handleError(error));
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="space-y-4">
        <FormField
          name="firstName"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("auth:firstName")}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="lastName"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("auth:lastName")}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="phone"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("auth:phone")}</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  value={PhoneFormattingUtils.formatPhoneNumber(field.value)}
                  onBlur={() => {
                    try {
                      form.setValue(
                        "phone",
                        PhoneFormattingUtils.formatPhoneNumber(PhoneValidationUtils.extractAndValidateNationalNumber(field.value, "CA"), "CA")
                      );
                      form.clearErrors("phone");
                    } catch (error) {
                      if (field.value.trim() === "") return;

                      if (error) {
                        if (typeof error === "string") {
                          form.setError("phone", {
                            type: "manual",
                            message: error,
                          });
                        }

                        if (error instanceof Error) {
                          form.setError("phone", {
                            type: "manual",
                            message: error.message,
                          });
                        }
                      }
                    }

                    field.onBlur();
                  }}
                  autoComplete="tel"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {displayBusinessType ? (
          <div className="pb-3">
            <FormField
              name="businessType"
              control={form.control}
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormLabel>{t("auth:businessType")}</FormLabel>
                  <FormControl>
                    <RadioGroup defaultValue={field.value} onValueChange={field.onChange} className="flex flex-col space-y-1">
                      <FormItem className="flex items-center md:space-y-0 gap-3 p-3 border rounded-lg">
                        <FormControl>
                          <RadioGroupItem value="individual" />
                        </FormControl>
                        <FormLabel className="font-normal flex flex-col gap-1">
                          {t("auth:individual")}
                          <span className="text-slate-600 text-xs">{t("auth:individualDescription")}</span>
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center md:space-y-0 gap-3 p-3 border rounded-lg">
                        <FormControl>
                          <RadioGroupItem value="company" />
                        </FormControl>
                        <FormLabel className="font-normal flex flex-col gap-1">
                          {t("auth:business")}
                          <span className="text-slate-600 text-xs">{t("auth:businessDescription")}</span>
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        ) : null}

        <FormField
          control={form.control}
          name="tos"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center space-x-3 space-y-0 md:space-y-0">
              <FormControl>
                <Checkbox id="acceptTermsOfUse" checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
              <Label htmlFor="acceptTermsOfUse">
                <Trans
                  i18nKey="auth:disclaimer"
                  components={{
                    tosLink: (
                      <a href={t("auth:tosLink")} className="text-rapide-600 underline hover:no-underline">
                        {t("auth:tosText")}
                      </a>
                    ),
                    privacyLink: (
                      <a href={t("auth:privacyLink")} className="text-rapide-600 underline hover:no-underline">
                        {t("auth:privacyText")}
                      </a>
                    ),
                  }}
                />
                <FormMessage />
              </Label>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="newsletterOptIn"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center space-x-3 space-y-0 md:space-y-0">
              <FormControl>
                <Checkbox id="newsletterOptIn" checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
              <Label htmlFor="newsletterOptIn">{t("auth:newsletterOptIn")}</Label>
            </FormItem>
          )}
        />

        <div className="pt-4 flex space-x-4">
          <Button type="button" size="md" variant="outline" onClick={onCancel} disabled={form.formState.isSubmitting}>
            {t("auth:cancel")}
          </Button>

          <Button
            type="submit"
            size="md"
            className="md:w-auto w-full"
            disabled={form.formState.isSubmitting || !form.formState.isValid}
            loading={form.formState.isSubmitting}
          >
            {t("auth:continue")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
