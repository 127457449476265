import { cookieOptions } from "@/lib/configs/cookie.config";
import { isSafeToRedirect } from "@/lib/configs/redirect.config";
import { setAuthTokenHeader } from "@/lib/services/config/api";
import { getConnectedAccount } from "@/lib/services/connect.services";
import { useLazyGetUserQuery } from "@/lib/services/user.services";
import { StorageUtils, keys } from "@/lib/utils/storage.utils";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";

export default function useOnAuth() {
  const [searchParams] = useSearchParams();
  const [_, setCookie] = useCookies([keys.AUTH_TOKEN]);
  const getUser = useLazyGetUserQuery();

  const authenticate = (authToken: string) => {
    setAuthTokenHeader(authToken);
    setCookie(keys.AUTH_TOKEN, authToken, cookieOptions);
  };

  const redirect = () => {
    let redirect: string | null = null;

    if (searchParams.get("redirect")) {
      redirect = decodeURIComponent(searchParams.get("redirect") as string);
    } else if (StorageUtils.getSessionItem(keys.AUTH_REDIRECT)) {
      redirect = StorageUtils.getSessionItem(keys.AUTH_REDIRECT);
    }

    if (!redirect || !isSafeToRedirect(redirect)) {
      redirect = import.meta.env.VITE_CLICKNPARK_DEFAULT_REDIRECT as string;
    }

    StorageUtils.clear();

    window.location.replace(redirect);
  };

  const needsToReviewTerms = async () => {
    try {
      const user = await getUser();
      return user.tosAgreementStatus !== "up-to-date";
    } catch (error) {
      return false;
    }
  };

  async function isOwnerAccount() {
    try {
      await getConnectedAccount();
      return true;
    } catch (error: unknown) {
      return false;
    }
  }

  return {
    authenticate,
    redirect,
    needsToReviewTerms,
    isOwnerAccount,
  };
}
